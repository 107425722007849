import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';








@Injectable({
  providedIn: 'root'
})

export class DataService {
  baseUrl : string;
  codeAbo: any;
  annee: any;
  parcours: any;
  dateParcours: any;
  anneeMois: any;
  dbService: any;
  dataService: any;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = 'https://obelixexpress.no-ip.biz';
//    console.log("window.location.hostname new");
//    console.log(window.location.hostname);
//    console.log(window.location.hostname=="");
    if (window.location.hostname ==='express.obelix') {
      this.baseUrl = 'https://express.obelix/';
    } else if (window.location.hostname ==="localhost") {
      this.baseUrl = '/api/';
    }

   }


   SauvegardeCloud(codeAbo,dateParcours,parcours,annee,anneeMois): Observable<any> {
    /* dateParcours ="";
    parcours ="";
    let codeAbo = "";
    anneeMois ="";
    annee = ""; */

    const data = {
      'abonne' : codeAbo,
      'saison' : annee,
      'parcours' : parcours,
      'DateParcours': dateParcours,
      'AnneeMois' : anneeMois
  };

  let headers = new HttpHeaders();
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }
    this.httpClient.post("https://mybikeexpressobelix.fr/php/parcoursMyBike.php",JSON.stringify(data), httpOptions)

    .subscribe(
        (val: any) => {
            console.log("POST call successful value returned in body",
                        val);
        },
      (        response: any) => {
            console.log("POST call in error", response);
        },
        () => {
            console.log("The POST observable is now completed.");
        });

return null;
}


 Reload(DB) {

       const data = {
         'abonne' : this.codeAbo,
         'saison' : this.annee,
         'parcours' : this.parcours,
         'DateParcours': this.dateParcours,
         'AnneeMois' : this.anneeMois
     };

     const httpOptions = {
      headers: new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Access-Control-Allow-Origin': '*',
                   'Access-Control-Allow-Credentials': 'true'
      })
    };

       let ABO = localStorage.getItem('codeAbonne');
     //  console.log("OELIX");
       this.httpClient.get("https://mybikeexpressobelix.fr/php/parcoursMyBike.php?id=18&abonne="+ABO, {responseType: 'json'})
       .subscribe(
           (response: any) => {
   //         console.log("GET call successful value returned in body ", response);
            var temp = response;
             DB
              .clear("MyBike")
              .subscribe(
                result => {
                  console.log("clear all" );
                });
            for (var i=0; i<response.length; i++)
            {
              console.log(i);
              console.log(response.length);
              console.log(response[i].parcours);
             DB
                .add('MyBike', {
                    dateEvent : response[i].DateParcours,
                    ParcoursVelo:  JSON.parse(response[i].parcours),
                    Annee :response[i].Saison,
                    AnneeMois :response[i].AnneeMois
              })
              .subscribe(
                result => {
                  console.log("reload "+i);
                });
           };
       });
   return null;
   }

   replaceAll(string, search, replace) {
    console.log(string);
    console.log(search);
    console.log(replace);
    return string.split(search).join(replace);
  }

UpdateCloud(codeAbo,dateParcours,parcours,annee,anneeMois): Observable<any> {

  const data = {
    'abonne' : codeAbo,
    'saison' : annee,
    'parcours' : parcours,
    'DateParcours': dateParcours,
    'AnneeMois' : anneeMois
};

let headers = new HttpHeaders();
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8'
  })
}

  var putString = "abonne="+codeAbo+"&parcours="+JSON.stringify(data.parcours)+"&date="+dateParcours;
 // console.log('https://mybikeexpressobelix.fr/php/parcoursMyBike.php?'+putString);
  this.httpClient.put("https://mybikeexpressobelix.fr/php/parcoursMyBike.php?"+putString, httpOptions)
  .subscribe(
      (val: any) => {
          console.log("PUT call successful value returned in body",
                      val);
      },
    (        response: any) => {
          console.log("PUT call in error", response);
      },
      () => {
          console.log("The PUT observable is now completed.");
      });

return null;
}
  postSauvegarde(listObjSauvegarde : any): Observable<any> {
    let listObjSauv = [];
    let abonne ="DBON";
    let saison ="2023";
    let parcours ="{test}";
    listObjSauv.push(abonne);
    listObjSauv.push(saison);
    listObjSauv.push(parcours);

   // console.log("OBELIX");
   // console.log(listObjSauv);
      return  this.httpClient.post(this.baseUrl+"/ExpressMobility/rest/MyBike/ExportDataMyBike",listObjSauv)
      .pipe(
        catchError(this.handleError)
        );
  }

  handleError(error: HttpErrorResponse){
  //  console.log(error.error.message);
  //  console.log(error.status);
  //  console.log(error.statusText);
    if (error.status== 403 ) return of({"status": "Pb d'authentifcation"});
    }
};

function httpPostExample() {

  let listObjSauv = [];
  let abonne ="DBON";
  let saison ="2023";
  let parcours ="{test}";
  listObjSauv.push(abonne);
  listObjSauv.push(saison);
  listObjSauv.push(parcours);


           this.httpClient.post("https://mybikeexpressobelix.fr/php/parcoursMyBike.php",listObjSauv)
           .header("Content-type: application/x-www-form-urlencoded\r\n")
               .subscribe(
                   (val: any) => {
                       console.log("POST call successful value returned in body",
                                   val);
                   },
                 (                   response: any) => {
                       console.log("POST call in error", response);
                   },
                   () => {
                       console.log("The POST observable is now completed.");
                   });
                   return "OK";
           };
